import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faUserFriends} from "@fortawesome/free-solid-svg-icons";
import './intro.css';


class Intro extends React.Component {


    constructor (props) {
        super(props)
        
        this.counter = 0;
        this.state = {
            'photo_url': Math.random() >= 0.5?'/images/header/happy_woman6.png':'/images/header/happy_woman5.png'
        }

        //bind
        this.openSearchWindow = this.openSearchWindow.bind(this)
        this.togglePhoto = this.togglePhoto.bind(this); 

    }

    togglePhoto(event) {
        //event.preventDefault();
        event.stopPropagation()
        
        // fixes double click even on mobile on the first time
        if (this.counter++===1 && event.type==='click') 
            return 

        if (this.state.photo_url==='/images/header/happy_woman5.png')
            this.setState({photo_url: '/images/header/happy_woman6.png'});
        else
            this.setState({photo_url: '/images/header/happy_woman5.png'});
    }

    openSearchWindow = (e) => {
        e.preventDefault()
        if (this.props?.hideSearch) {
            window.location = '/search'
        }
        this.props.openSearchWindow('0',false);
    }

    render() {
        if (!this.props?.hideHomepageIntro && !this.props?.active || this.props?.active=='home' )
            return(
            <div className="homepage-intro-wrapper">
                <div className="homepage-intro">
                    {/*<img src={'/images/header/happy_woman5.png'} style={{display:'none'}} alt="אישה שהזמינה חופשה"/>
                    <img src={'/images/header/happy_woman6.png'} style={{display:'none'}} alt="אישה שהזמינה חופשה"/>*/}
                    <div className="homepage-intro-content" onClick={e=>{this.togglePhoto(e)}} onMouseOver={e=>{this.togglePhoto(e)}} onMouseOut={e=>{this.togglePhoto(e)}}>
                        <div className="slogan" title="באנר: אנחנו נמצא לכם חופשה, אתם תשלמו פחות">
                            <div className="line1">אנחנו נמצא לכם חופשה</div>
                            <div className="line2">אתם תשלמו פחות</div>
                            {this.props?.page_type!=='destination'?
                                (
                                    <div className="line3">רק בעפנו מוצאים את התאריכים עם <strong style={{color:'#fed102'}}>השילובים</strong><br /> הזולים 
                                    ביותר של <strong  style={{color:'#fed102'}}>טיסה+מלון</strong> בשנה הקרובה</div>
                                ):''
                            }
                            
                            <a href="#" onClick={e=>{this.openSearchWindow(e)}} title="לחצו לחיפוש דילים" className="searchBoxButton" style={{display: this.props.hideSearchBox ? 'none' : ''}}>
                                <div className="searchBox" >   
                                    <div title="כותרת: חפשו את החופשה שלכם (לחצו לפתיחת חיפוש מותאם אישית)"className="right" ><FontAwesomeIcon icon={faSearch} style={{color: '#555', fontSize: '1em',  margin: '0 0 0 0.3em'}} />חפשו את החופשה שלכם</div>
                                    <div title="כותרת: 2 מבוגרים (לחצו לפתיחת חיפוש מותאם אישית)" className="left"><FontAwesomeIcon icon={faUserFriends} style={{color: '#29bbd8', fontSize: '1em',  margin: '0 0 0 0.3em'}} /> {
                                        this.props?.filters?.teens + this.props?.filters?.children + this.props?.filters?.infants == 0 ?
                                        `${this.props?.filters?.adults} מבוגרים`:
                                        this.props?.filters?.adults==2 && this.props?.filters?.children + this.props?.filters?.infants > 0 && this.props?.filters?.teens == 0?
                                            `זוג+${this.props?.filters?.children*1 + this.props?.filters?.infants*1}`:
                                            `${this.props?.filters?.adults*1 + this.props?.filters?.teens*1 + this.props?.filters?.children*1 + this.props?.filters?.infants*1} נוסעים`
                                    }</div>
                                </div></a>
                            <div className="line4" style={{marginTop: this.props.hideSearchBox ? '120px' : 'inherit'}}></div>
                        </div>
                        {/*<div className="image">
                            <img src={this.state.photo_url} alt="חופשות סודיות"></img>
                        </div>*/}
                    </div>
                </div>
                {/*<div style={{height:'150px', width:'100%', marginTop:'20px',  backgroundImage:'url(/images/promotions/black-friday-banner-2000x150.jpg)'}}></div>*/}
            </div>
            )
        else
            return (<></>);
    }
}

export default Intro