import React from "react"
import CardLine from "./CardLine";
import CardHeader from "./CardHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBed, faPlane, faAngleDoubleLeft  } from "@fortawesome/free-solid-svg-icons"; 


class Card extends React.Component {
    constructor (props) {
        super(props)

        let city_name = this.props.city_name
        let city_id = this.props?.city_id;
        let prefix = `/${city_name}/?`;
        let destination_param = `destinations=${city_id}`;
        //let url = `/${city_name}/?destinations=${city_id}`;

        
        // &min_stars=4&max_stars=5&min_nights=5&max_nights=7&when=all&when_caption=%D7%91%D7%AA%D7%90%D7%A8%D7%99%D7%9B%D7%99%D7%9D%20%D7%94%D7%9B%D7%99%20%D7%96%D7%95%D7%9C%D7%99%D7%9D&from=&to=&limit=10&destination=59&destination_name=%D7%90%D7%A0%D7%98%D7%90%D7%9C%D7%99%D7%94&sortby=price-asc&months=&dates=&QS=null
        let params = ``;
        
        if (this.props?.stars && this.props?.stars!='') {
            params += `&stars=${this.props?.stars}`;
        }

        if (this.props?.nights && this.props?.nights!='') {
            params += `&nights=${this.props?.nights}`;
        }

        if (this.props?.meals && this.props?.meals!='') {
            params += `&meals=${this.props?.meals}`;
        }

        if (this.props?.airlines && this.props?.airlines!='') {
            params += `&airlines=${this.props?.airlines}`;
        }

        if (this.props?.months && this.props?.months!='') {
            params += `&months=${this.props?.months}`;
        }

        if (this.props?.adults && this.props?.adults!='') {
            params += `&adults=${this.props?.adults}`;
        }

        if (this.props?.teens && this.props?.teens!='') {
            params += `&teens=${this.props?.teens}`;
        }


        if (this.props?.children && this.props?.children!='') {
            params += `&children=${this.props?.children}`;
        }

        if (this.props?.infants && this.props?.infants!='') {
            params += `&infants=${this.props?.infants}`;
        }

        if (this.props?.dow && this.props?.dow!='') {
            params += `&dow=${this.props?.dow}`;
        }

        if (this.props?.waterpark ) {
            params += `&waterpark=1`;
        }

        if (this.props?.luxury ) {
            params += `&luxury=1`;
        }

        if (this.props?.casino ) {
            params += `&casino=1`;
        }

        if (this.props?.near_chabad ) {
            params += `&chabad=1`;
        }

        if (this.props?.spa ) {
            params += `&spa=1`;
        }

        if ((this.props?.from_date && this.props?.from_date!='' && this.props?.to_date && this.props?.to_date!='')) {
            const date_caption = this.props.from_date.split('-')[2]+'.'+this.props.from_date.split('-')[1]+'.'+this.props.from_date.split('-')[0] + ' עד ' + this.props.to_date.split('-')[2]+'.'+this.props.to_date.split('-')[1]+'.'+this.props.to_date.split('-')[0]
            prefix = `/${city_name}/${date_caption}/?`;
            params += `&from_date=${this.props.from_date}&to_date=${this.props.to_date}&dates_type=${this.props.dates_type}&month=`
        }

        //if (this.props.months && this.props.months.length==1) {
        if (this.props.months && this.props.months.length == 1) {
            let urlParamName = 'דילים'
            if (this.props.months.length==1)
                urlParamName = this.props.months[0];
            prefix = `/${city_name}/${urlParamName}/?`;
            params += `&month=${this.props.months.toString()}`
        }

        var url=prefix + destination_param + params;
        
        this.state = {
            'open': false,
            destination_url: url
        }

      
    }
    

    handleClickGoToDestination = (event, click_location) => {
        this.props.GoToDestination(event, this.props.city_id, this.props.city_uid, this.props.city_name, click_location);
    }

    

    handleToggleDealDetails = (e) => {
        e.preventDefault()
        console.log('Details')
        this.setState(prevState=>({
            'open': !prevState.open
        }))
    }

    render() {

        const item = this.props?.data;
        let more = '';
            
        // MORE DEALS LINK
        if (this.props?.type!='days')
            more = (
                <a href={this.state?.destination_url}  title={'עוד דילים ל'+this.props?.city_name} style={{textDecoration:'none'}}>
                <div style={{lineHeight:'2.5rem', display:'flex', justifyContent:'center', alignItems:'center', background:'#1e5881', fontWeight: 700, color:'#fff', cursor:'pointer', borderBottomLeftRadius:'5px', borderBottomRightRadius:'5px'}} className={'showMore'} 
                     >
                        <div className='caption'>עוד דילים ל{this.props?.city_name} </div>
                        <div className='icon' style={{margin:'0 .5em 0 0'}}>
                            <FontAwesomeIcon icon={faAngleDoubleLeft}  />
                        </div>
                    </div>
                </a>
            );


        let group_id, city_id, meal_plan, from_date, to_date, stars, property_name;
        
        if (this.props?.data) {
            const deal = this.props.data;
        
            meal_plan = deal.property.meal_code;
            from_date = deal.departure && deal.departure.date ? deal.departure.date.split('T')[0]:'';
            to_date = deal.return && deal.return.date ? deal.return.date.split('T')[0]:'';
            stars = deal.property.stars;
            property_name = deal.property.name;
            city_id = deal.destination.city.id;
            group_id = deal.gid;

        }

        let item_uri = `/deal/${item.uid}?cid=${city_id}&gid=${group_id}&out=${from_date}&in=${to_date}&a=${this.props.adults?this.props.adults:0}`;
        this.props.teens && this.props.teens>0 ? item_uri += `&t=${this.props.teens}` : item_uri += `&t=0`;
        this.props.children && this.props.children>0 ? item_uri += `&c=${this.props.children}` : item_uri += `&c=0`;
        this.props.infants && this.props.infants>0? item_uri += `&i=${this.props.infants}` : item_uri += `&i=0`;
        item_uri+=`&s=${stars}&mp=${meal_plan}&hn=${property_name}`;

        let html = (
            <div key={`card_${this.props?.index}`} className={'dealcard'} >                    
                    <CardHeader type={this.props?.type} ShowCardLine={this.ShowCardLine} data={item} city_uid={this.props?.city_uid} city={this.props?.city_name} country={this.props?.country_name} target_url={this.props?.type!='days'?this.state?.destination_url:item_uri} />
                    <a href={item_uri} onClick={e=>this.handleToggleDealDetails(e)} style={{textDecoration:'none'}}>
                        <CardLine 
                        data={item} 
                        ToggleDealDetails={this.handleToggleDealDetails}  
                        open={this.state?.open} slug={item.code} 
                        key={'cardline'+this.props?.index} 
                        counter={(this.props.counter)} 
                        total={this.total} 
                        type={this.props.type} 
                        stars={this.props.stars} 
                        show={this.props.show} 
                        city_name={this.props?.city_name} 
                        country_name={this.props?.country_name}  
                        month={this.props.month} 
                        adults={this.props?.adults} 
                        teens={this.props?.teens}
                        children={this.props?.children} 
                        infants={this.props?.infants} 
                        dow={this.props?.dow} /></a>
                    {more}
            </div>
        )

        return html;

    }
}
export default Card